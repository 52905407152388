// Provide Chakra as high in the React component tree as possible
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

import "@fontsource/open-sans/400.css"
import "@fontsource/nunito-sans/900.css"
import theme from './theme'
import React from 'react'
import Layout from './components/layout'

// const config = {
//     initialColorMode: "dark",
//     useSystemColorMode: false,
// }

// const theme = extendTheme({ config })

export const wrapPageElement = ({ element }) => {
    return (
        <ChakraProvider resetCSS theme={theme}>
            <ColorModeScript
                initialColorMode={theme.config.initialColorMode}
                key="chakra-ui-no-flash"
                />
            <Layout>
                {element}
            </Layout>
        </ChakraProvider>
    )
}