import * as React from "react"
import PropTypes from "prop-types"
import useSticky from "../hooks/useSticky"

import "./layout.css"

const Layout = ({ children }) => {
  const { element } = useSticky()

  return (
    <div className="site">
        <main id="main-content">
          <div ref={element}>
            {children}
          </div>
        </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
