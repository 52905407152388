import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
    sm: "360px",
    md: "768px",
    lg: "1024px",
    xl: "1440px"
});

const theme = { 
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    breakpoints,
    colors: {
        red: {
            400: '#BF4E30'
        },
        steel: {
            '50':  '#f4f8f9',
            '100': '#dcf1fb',
            '200': '#b3e0f6',
            '300': '#81c0e8',
            '400': '#4d9cd5',
            '500': '#3383c2',
            '600': '#305fad',
            '700': '#28488a',
            '800': '#1c3063',
            '900': '#111e40',
          },
          sunset: {
            '50':  '#fcfbf9',
            '100': '#fbf0e3',
            '200': '#f6d4c4',
            '300': '#eaa996',
            '400': '#e27a67',
            '500': '#bf4e30',
            '600': '#b63c2d',
            '700': '#8e2d22',
            '800': '#641f18',
            '900': '#3d130e',
          },
        brand: {
            400: '#323F07'
        },
},
    styles: {
        global: {
            body: {
                fontFamily: 'Open Sans, sans-serif'
            },
            a: {
                color: 'steel.600',
                _hover: {
                    textDecoration: 'none',
                    color: 'sunset.500'
                },
                _active: {
                    color: 'sunset.500'
                }
            },
            h1: {
                fontFamily: 'Nunito Sans, sans-serif'
            },
            h4: {
                fontFamily: 'Nunito Sans, sans-serif',
                fontSize: 'xl'
            },
            p: {
                marginBottom: '1rem'
            },
            li: {
                marginLeft: '1.5rem'
            }
        }
    },
    fonts: {
        body: 'Open Sans',
        heading: 'Nunito Sans',
    },
    components: {
        Text: {
            marginBottom: '1rem'
        },
        Link: {
            variants: {
                "sidebar-nav-item": {
                    color: "white",
                    fontFamily: "Nunito Sans, sans-serif",
                    fontSize: "1.25rem",
                    _hover: {
                        color: 'sunset.300'
                    },
                    active: {
                        color: 'sunset.300'
                    }
                },
                "blog-link": {
                    color: "steel.700",
                    fontWeight: "700",
                    _hover: {
                        color: "sunset.500"
                    }
                }
            }
        },
        Button: {
            baseStyle: {
                fontWeight: "bold",
                fontFamily: 'Nunito Sans, sans-serif',
                textTransform: 'uppercase',
                textDecoration: 'none'
              },
              variants: {
                  "project-card-button": {
                      _active: {
                          background: 'none !important',
                      }
              }
            }
        }
    }

}

export default extendTheme(theme)